<template>
  <v-container class="mt-3 px-sm-10 px-0 elevation-0" fluid>
    <PageHeader
      header-text="Exports"
      class="mb-7"
    >
      <template #subheader>
        View your active and previous exports
      </template>
    </PageHeader>

    <div v-if="currentExports.length" class="field__header mt-10">
      Active Exports
    </div>
    <ExportsTable
      v-if="currentExports.length"
      :exports="currentExports"
      :for-previous-exports="true"
      @canceled="handleCanceledExport"
    />

    <div class="field__header mt-10">
      Previous Exports
    </div>
    <ExportsTable
      :exports="previousExports"
    />
    <PaginationButtons
      :current-page-number="exportsPageNumber"
      :current-page-size="exportsPageSize"
      :count-total="exportsTotal"
      :page-sizes="[25, 50, 100]"
      @next-page="getExportsNextPage"
      @prev-page="getExportsPrevPage"
      @change-page-size="exportsChangePageSize"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import ExportsTable from "@/views/Exports/components/ExportsTable";
import PaginationButtons from "@/sharedComponents/pagination/PaginationButtons";

export default {
  name: "ExportsView",
  metaInfo: {
    title: 'Exports'
  },
  components: {
    PageHeader,
    ExportsTable,
    PaginationButtons,
  },
  data() {
    return {
      currentExports: [],
      previousExports: [],
      eventSource: null,
      completedStatuses: ['completed', 'canceled', 'failed'],
      previousStatuses: ['queued', 'preparing', 'analyzing', 'requires action', 'processing', 'finalizing'],
      exportsPageNumber: 1,
      exportsPageSize: 25,
      exportsTotal: 0,
    };
  },
  async created() {
    await this.getPreviousExports();
    await this.getCurrentExports();

    this.eventSource = await this.$store.dispatch('mercure/exports_subscribe');
    if (!this.eventSource) {
      return;
    }

    this.eventSource.onmessage = (msg) => {
      this.$store.dispatch('mercure/update_exports_last_event', msg.lastEventId);

      const parsedMessage = JSON.parse(msg.data);

      const currentExportIndex = this.currentExports.findIndex(elem => elem.id === parsedMessage.id) ?? null;
      if (currentExportIndex === -1) {
        this.currentExports.unshift(parsedMessage);
        return;
      }

      if (this.completedStatuses.includes(parsedMessage.status)) {
        this.$delete(this.currentExports, currentExportIndex);
        this.getPreviousExports();

        this.$store.commit('snackbar/showMessage', {
          color: "success",
          content: `An export has completed. Please check the section below for the results.`
        })
        return;
      }

      this.$set(this.currentExports, currentExportIndex, parsedMessage);
    };
  },
  beforeUnmount() {
    if (!this.eventSource) {
      return;
    }
    this.$store.dispatch('mercure/unsubscribe', this.eventSource)
  },
  methods: {
    async getPreviousExports() {
      const resp = await this.$rest.exports.get_collection({
        limit: this.exportsPageSize,
        page: this.exportsPageNumber,
        status: this.completedStatuses,
        sort: ['updatedAt:desc'],
      });
      this.previousExports = resp.data.items;
      this.exportsTotal = resp.data.totalCount;
    },
    async getCurrentExports() {
      const resp = await this.$rest.exports.get_collection({
        status: this.previousStatuses,
        sort: ['createdAt:desc'],
      });
      this.currentExports = resp.data.items;
    },
    async getExportsNextPage() {
      this.exportsPageNumber += 1;
      await this.getPreviousExports();
    },
    async getExportsPrevPage() {
      this.exportsPageNumber -= 1;
      await this.getPreviousExports();
    },
    async exportsChangePageSize(size) {
      this.exportsPageSize = size;
      this.exportsPageNumber = 1;
      await this.getPreviousExports();
    },
    async handleCanceledExport() {
      await this.getCurrentExports();
      await this.getPreviousExports();
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-hint {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #66788e;
}

.field {
  &__header {
    margin-bottom: 25px;
  }
}
</style>
